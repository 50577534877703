<!-- src/components/DropdownGenerator.vue -->
<template>
  <div class="generatorDiv">
    <!-- Dropdowns -->
    <div class="container">
      <div class="row mb-3 ">
            <div class="col-md-3 themed-grid-col">
              <img class="logo" src="../img/TigerPromptLogo.png">
            </div>
        <div class="col-md-9 themed-grid-col">
      <textarea class="form-control yourPrompt" :class="{ 'error': generalTopicHasError }"
                v-model="generalTopic"
                :placeholder="translations.labels.generalTopic"
                ref="yourIdeaPrompt"
                id="yourIdeaPromptId"
      >

      </textarea>
          <!-- Button to toggle collapse -->
          <Link
              class="btn btn-link buttonRight"
              type="button"
              @click="toggleCollapse"
          >
            {{ collapsed ?  translations.labels.moreInfoButton : translations.labels.lessInfoButton }}
          </Link>
          <div class="text-danger" ref="yourIdeaPromptError">{{ translations.labels.generalTopicError }}</div>

        </div>
      </div>
      <br>
      <div class="row mb-3">

        <!-- Collapsing area with information text -->
        <div v-if="!collapsed" class="moreInfo" v-html="translations.labels.moreInfo"></div>

        <div class="col-md-6 themed-grid-col">
          <label for="role" class="form-label">{{ translations.labels.role }}</label>
          <select class="form-select" v-model="role">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="optionKey in dropdowns.role" :value="optionKey" :key="optionKey">
              {{ translations.dropdown.role[optionKey] }}
            </option>
          </select>
        </div>

        <div class="col-md-6 themed-grid-col">
          <label for="resultType" class="form-label">{{ translations.labels.resultType }}</label>
          <select class="form-select" v-model="resultType">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.resultType" :value="option" :key="option">
              {{ translations.dropdown.resultType[option] }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 themed-grid-col">
          <label for="textLength" class="form-label">{{ translations.labels.textLength }}</label>
          <select class="form-select" v-model="textLength">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.textLength" :value="option" :key="option">
              {{ translations.dropdown.textLength[option] }}
            </option>
          </select>
        </div>

        <div class="col-md-6 themed-grid-col">
          <label for="style" class="form-label">{{ translations.labels.style }}</label>
          <select class="form-select" v-model="style">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.style" :value="option" :key="option">
              {{ translations.dropdown.style[option] }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 themed-grid-col">
          <label for="formality" class="form-label">{{ translations.labels.formality }}</label>
          <select class="form-select" v-model="formality">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.formality" :value="option" :key="option">
              {{ translations.dropdown.formality[option] }}
            </option>
          </select>
        </div>

        <div class="col-md-6 themed-grid-col">
          <label for="creativity" class="form-label">{{ translations.labels.creativity }}</label>
          <select class="form-select" v-model="creativity">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.creativity" :value="option" :key="option">
              {{ translations.dropdown.creativity[option] }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 themed-grid-col">
          <label for="challenge" class="form-label">{{ translations.labels.challenge }}</label>
          <select class="form-select" v-model="challenge">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.challenge" :value="option" :key="option">
              {{ translations.dropdown.challenge[option] }}
            </option>
          </select>
        </div>
        <div class="col-md-6 themed-grid-col">
          <label for="language" class="form-label">{{ translations.labels.language }}</label>
          <select class="form-select" v-model="language">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.language" :value="option" :key="option">
              {{ translations.dropdown.language[option] }}
            </option>
          </select>
        </div>
      </div>

      <div class="row mb-3">
        <div class="col-md-6 themed-grid-col">
          <label for="textLanguage" class="form-label">{{ translations.labels.textLanguage }}</label>
          <select class="form-select" v-model="textLanguage">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.textLanguage" :value="option" :key="option">
              {{ translations.dropdown.textLanguage[option] }}
            </option>
          </select>
        </div>

        <div class="col-md-6 themed-grid-col">
          <label for="textType" class="form-label">{{ translations.labels.textType }}</label>
          <select class="form-select" v-model="textType">
            <option value="" disabled selected>{{ translations.labels.selectAOption }}</option>
            <option v-for="option in dropdowns.textType" :value="option" :key="option">
              {{ translations.dropdown.textType[option] }}
            </option>
          </select>
        </div>
      </div>

      <br>

      <div class="row mb-3">
        <div class="col-12 jailbreakBorder">
          <div class="form-check form-switch">
            <input v-model="isDarkTheme" @change="toggleDarkTheme" class="form-check-input" type="checkbox"
                   id="jailbreak">
            <label for="jailbreak" class="form-check-label">{{ translations.labels.jailbreak }}</label>
          </div>

        </div>
      </div>
      <br>
      <div class="row mb-3">
        <div class="col-12">
          <label class="form-check-label">{{ translations.labels.keywords }}</label>
          <input v-model="keywords" placeholder="Keywords" class="form-control">
        </div>
      </div>
      <br>
      <div class="row mb-3">
        <div class="col-12">
          <label class="form-check-label">{{ translations.labels.temperature }}</label>
          <div class="center">
            <input type="range" v-model="temperatureSlider" class="form-range">
            <span>{{ temperatureSlider }}</span>
          </div>
        </div>
      </div>
      <br>
      <!-- Generate Prompt Button -->
      <div class="row mb-3">
        <button class="btn btn-primary" @click="generatePrompt">{{ translations.labels.generatePromptButton }}</button>
      </div>
      <br><br>

      <!-- Display the Generated Prompt -->
      <div class="mb-3" v-if="generatedPromptCopied" style="color: green;">{{ translations.labels.promptCopied }}</div>
      <textarea
          v-model="generatedPromptTextArea"
          isResultBlurred
          class="form-control" :class="{ 'promptIsReady': promptIsReady, 'isResultBlurred': isResultBlurred }"
          :style="{ borderColor: generatedPromptCopied ? 'green' : '' }"
          ref="promptTextArea"
          id="promptResult"
      ></textarea>

      <!-- Copy Prompt Button --><br>
      <div class="center">

        <button class="btn btn-success" @click="copyPrompt">{{ translations.labels.copyPrompt }}</button>
      </div>
      <!-- Modal component -->
      <div v-if="showModal" class="modal">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ translations.labels.subscribeHeadline }}</h5>
              <button type="button" class="close" @click="showModal = false">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p v-if="!subscribed">{{ translations.labels.subscribeSubHeadline }}</p>
              <form @submit.prevent="subscribed ? unlockPrompt() : submitEmail()"
                    :class="{ 'was-validated': isCodeValid, 'shake-modal': isShakingModal }">
                <div class="mb-3" v-if="!subscribed">
                  <input v-model="email" required type="email" class="form-control" :placeholder="translations.labels.yourEmailInput" >
                </div>
                <div class="mb-3" v-if="!subscribed">
                  <input v-model="name" required type="text" class="form-control" :placeholder="translations.labels.yourName" >
                </div>

                <!-- Hidden Fields for UTM Parameters -->
                <div class="utm_source_css">
                <input type="hidden" v-model="utm_source" name="utm_source">
                </div>
                <div class="utm_campaign_css">
                <input type="hidden" v-model="utm_campaign" name="utm_campaign">
                </div>
                <div class="utm_medium_css">
                <input type="hidden" v-model="utm_medium" name="utm_medium">
                </div>
                <div class="utm_term_css">
                <input type="hidden" v-model="utm_term" name="utm_term">
                </div>
                <div class="utm_content_css">
                <input type="hidden" v-model="utm_content" name="utm_content">
                </div>
                <div class="utm_id_css">
                <input type="hidden" v-model="utm_id" name="utm_id">
                </div>

                <div v-if="isEmailInvalid" class="codeInvalid">
                  {{ translations.labels.emailIncorrect }}
                </div>
                <div v-if="isEmailAlreadyExist" class="codeInvalid">
                  {{ translations.labels.isEmailAlreadyExist }}
                </div>
                <div class="mb-3" v-if="subscribed">
                  <h3>{{ translations.labels.checkMails }}</h3>
                  <input v-model="code" type="text" class="form-control" placeholder="Code">
                </div>
                <div v-if="isCodeInvalid" class="codeInvalid">
                  {{ translations.labels.codeIncorrect }}
                </div>
                <button type="submit" class="btn btn-primary" :disabled="loading">
                  <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  {{ subscribed ? translations.labels.unlock : translations.labels.subscribeGetCode }}
                </button>
                <p v-if="!subscribed">
                  Already have a code? <a href="#" @click="toggleSubscribed">I've already a Code</a>
                </p>
                <p v-if="subscribed">
                  Need a code? <a href="#" @click="toggleSubscribed">Subscribe & Get code</a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <div class="row mb-3">
        <div class="col-12">
        <div class="center">
        <img class="bottomLogo" src="../img/TigerBottom.png">

      </div>
      </div>
      </div>
    </div>
  </div>

</template>

<script>
import axios from 'axios';


export default {
  props: ['translations'],
  data() {
    return {
      loading: false,
      utm_source: '',
      utm_medium: '',
      utm_term: '',
      utm_content: '',
      utm_campaign: '',
      utm_id: '',
      collapsed: true, // Initially, the area is collapsed
      isCookieSet: false, // Add a data property to track the cookie state
      isShakingModal: false, // Add a property to track modal shake effect
      isEmailValid: true, // Initially, assume email is valid
      isEmailInvalid: false, // Initially, assume email is valid
      isEmailAlreadyExist: false, // Initially, assume email is valid
      isCodeValid: false,  // Initially, assume code is valid
      isCodeInvalid: false,  // Initially, assume code is valid
      isCodeSending: false, // To track if code verification is in progress
      clearPrompt: '', // To store the clear text prompt
      isResultBlurred: false, // To track if the result is blurred
      showModal: false, // To control the visibility of the modal
      email: '', // To store the user's email
      subscribed: false, // To track if the user is subscribed
      generalTopicHasError: false,
      promptIsReady: false,
      role: '',
      isDarkTheme: false,
      resultType: '',
      textLength: '',
      style: '',
      formality: '',
      creativity: '',
      challenge: '',
      textLanguage: '',
      textType: '',
      language: '',
      generalTopic: '',
      keywords: '',
      temperatureSlider: 0,
      generatedPromptTextArea: '',
      currentTranslations: {}, // This will hold the translations of the currently selected language.
      dropdowns: {
        role: [
          "fiveYearsOld", "Experte", "Entrepreneur", "Journalist", "Jurist", "Laie",
          "Mandant", "Programmierer", "Redakteur", "Schüler", "Selbstständiger",
          "Steuerberater", "Student", "Wissenschaftler", "Yoda"
        ],

        resultType: [
          "Anleitung", "Artikel", "Argumentation", "Biografie", "Brief", "Drehbuch",
          "E-Mail", "Exzerpt", "Gedicht", "Geschichte", "Gutachten", "Management Summary",
          "Post", "Schulungsmaterial", "Song", "Stellungnahme", "Tagebucheintrag",
          "Unternehmensvision", "Vortrag", "Zusammenfassung"
        ],

        textLength: [
          "Ausführlich", "Kurz", "bis 50 Wörter", "50 bis 100", "100 bis 250",
          "250 bis 500", "500 und mehr"
        ],

        style: [
          "Argumentativ", "Bildhaft", "Eloquent", "Emotional", "Empathisch", "Fesselnd",
          "Freundlich", "Höflich", "Informativ", "Konträr", "Lebhaft", "Provokativ",
          "Sachlich und Formal", "Sympathisch", "Überzeugend", "Umgangssprachlich",
          "Wissenschaftlich", "Witzig und unterhaltend"
        ],

        formality: [
          "Absätze", "Anrede", "Gliederungspunkte", "Programmcode", "Überschriften"
        ],

        creativity: [
          "Anspielungen verwenden", "Ironie verwenden", "Metaphern verwenden",
          "Perspektiven beleuchten", "Pro und Contra", "Rap", "Reime verwenden",
          "Sarkasmus verwenden", "Unerwartete Wendung einbauen", "Veröffentlichungen zitieren",
          "Wortspiele verwenden", "Zitat einbauen"
        ],

        challenge: [
          "Bildbeschreibung für Midjourney", "Brainstorming", "FaQ", "Mit Fragen beginnen",
          "SEO Optimierung"
        ],

        language: [
          "Arabisch", "Chinesisch", "Dänisch", "Deutsch", "Englisch", "Französisch",
          "Griechisch", "Hindi", "Italienisch", "Japanisch", "Koreanisch",
          "Niederländisch", "Norwegisch", "Polnisch", "Portugiesisch", "Russisch",
          "Schwedisch", "Spanisch", "Türkisch", "Ungarisch"
        ],

        textLanguage: [
          "Ausdrücke verwenden", "Aussagekräftige Adjektive", "Fachsprache", "Fremdwörter verwenden",
          "Gendern", "Jugendsprache verwenden", "Kindersprache verwenden", "Kurze Sätze",
          "Satzzeichen", "Umgangssprache", "Verneinungen verwenden"
        ],

        textType: [
          "Artikel", "Blog", "Buch", "Buchkapitel", "Buchrezension", "E-Book", "FAQ",
          "Magazin", "Paper", "Podcast", "Report", "Skript", "Website", "Zeitschrift"
        ]
      }
    }
  },
  created() {
    // Check if the cookie is already set when the component is created
    this.isCookieSet = this.getCookie('codeForPromptWasCorrect') === 'true';
    // Add code to extract and store URL parameters
    //const urlParams = new URLSearchParams(window.location.search);
    // Prüfen, ob `handl_utm` global verfügbar ist und die Werte zuweisen
   if (typeof window.handl_utm !== 'undefined') {
     this.utm_source = window.handl_utm.utm_source || '';
     this.utm_medium = window.handl_utm.utm_medium || '';
     this.utm_term = window.handl_utm.utm_term || '';
     this.utm_content = window.handl_utm.utm_content || '';
     this.utm_campaign = window.handl_utm.utm_campaign || '';
     this.utm_id = window.handl_utm.utm_id || '';
   }
  },
  methods: {
    toggleSubscribed() {
      this.subscribed = !this.subscribed;
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed; // Toggle the collapsed state
    },
    setInputValue(selector, value) {
      var inputFields = document.querySelectorAll(selector);
        inputFields.forEach(function(inputField) {
          inputField.value = value;
        });
  },
    async submitEmail() {
      // Implement email subscription logic here
      // After successful subscription, set subscribed to true and send the code via email
      // For simplicity, we'll directly set subscribed to true here
      // Assuming you have a URL for the PHP script
      this.loading = true;
      // Construct the query string with the required parameters
      const queryString = `utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_term=${this.utm_term}&utm_content=${this.utm_content}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}`;

      // Überprüfen, ob die handl_utm Variable vorhanden ist
        if (typeof window.handl_utm !== 'undefined') {
            if (window.handl_utm.utm_source) {
                this.setInputValue('input[placeholder="utm_source"]', window.handl_utm.utm_source);
                this.setInputValue('.utm_source_css input[type="hidden"]', window.handl_utm.utm_source);
            }
            if (window.handl_utm.utm_campaign) {
                this.setInputValue('input[placeholder="utm_campaign"]', window.handl_utm.utm_campaign);
                this.setInputValue('.utm_campaign_css input[type="hidden"]', window.handl_utm.utm_campaign);
            }
            if (window.handl_utm.utm_content) {
                this.setInputValue('input[placeholder="utm_content"]', window.handl_utm.utm_content);
                this.setInputValue('.utm_content_css input[type="hidden"]', window.handl_utm.utm_content);
            }
            if (window.handl_utm.utm_term) {
                this.setInputValue('input[placeholder="utm_term"]', window.handl_utm.utm_term);
                this.setInputValue('.utm_term_css input[type="hidden"]', window.handl_utm.utm_term);
            }
            if (window.handl_utm.utm_medium) {
                this.setInputValue('input[placeholder="utm_medium"]', window.handl_utm.utm_medium);
                this.setInputValue('.utm_medium_css input[type="hidden"]', window.handl_utm.utm_medium);
            }
            if (window.handl_utm.utm_id) {
                this.setInputValue('input[placeholder="utm_id"]', window.handl_utm.utm_id);
                this.setInputValue('.utm_id_css input[type="hidden"]', window.handl_utm.utm_id);
            }
        }


      // Append the query string to the URL
      const url = `https://www.obviousworks.ch/promptBuilder/aWeberPHP/php/sendSubs.php?${queryString}`;

      // Send the entered code to the PHP script
      this.isCodeSending = true;
      // Create an object to hold the email and URL parameters
      const requestData = {
        email: this.email,
        name: this.name,
        utm_source: this.utm_source,
        utm_medium: this.utm_medium,
        utm_term: this.utm_term,
        utm_content: this.utm_content,
        utm_campaign: this.utm_campaign,
        utm_id: this.utm_id
      };
      try {
        const response = await axios.post(url, requestData);

        if (response.status === 201 && response.data.message === 'Subscription successful') {
          // Handle successful subscription
          this.isShakingModal = false;
          this.isCodeInvalid = false;
          this.subscribed = true;
          this.isEmailAlreadyExist = false;
          this.isEmailInvalid = false;
        }
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data.error === 'Subscriber already exists') {
          this.isEmailAlreadyExist = true;
          this.isEmailInvalid = false;
        } else {
          this.isEmailInvalid = true;
          this.isEmailAlreadyExist = false;
        }
        this.isShakingModal = true;
        setTimeout(() => {
          this.isShakingModal = false;
        }, 500);
      } finally {

        this.loading = false;
      }

    },

    async unlockPrompt() {
      this.loading = true;
      // Construct the query string with the required parameters
      const queryString = `utm_source=${this.utm_source}&utm_medium=${this.utm_medium}&utm_term=${this.utm_term}&utm_content=${this.utm_content}&utm_campaign=${this.utm_campaign}&utm_id=${this.utm_id}`;

      // Append the query string to the URL
      const url = `https://www.obviousworks.ch/promptBuilder/giveMeAccess.php?${queryString}`;

      //check for cookie codeForPromptWasCorrect, if set and true, set subscribed to false

      // Send the entered code to the PHP script
      this.isCodeSending = true;
      try {
        const response = await axios.post(url, {code: this.code});

        // Handle the response from the PHP script
        if (response.data === true) {
          // Code is correct, reset the validation state and modal shake effect
          this.isShakingModal = false;
          this.isCodeInvalid = false;

          // Code is correct
          this.isCodeValid = true;
          // Implement code unlocking logic here
          // After successful unlock, set subscribed to false
          this.subscribed = false;
          // Clear the code input
          this.code = '';
          // Close the modal
          this.showModal = false;
          // Set a cookie if the code is correct
          this.setCookie('codeForPromptWasCorrect', 'true', 365); // 365 days expiration

        } else {
          // Code is incorrect, trigger the validation state and modal shake effect
          this.isCodeInvalid = true;
          this.isShakingModal = true;
          // Code is incorrect
          this.isCodeValid = false;
          // Add a delay to stop shaking after a short duration
          setTimeout(() => {
            this.isShakingModal = false;
          }, 500);
        }
      } catch (error) {
        console.error('Error:', error);
        // Handle the error, for example, show an error message
        this.isCodeValid = false;
      } finally {
        this.isCodeSending = false;
        this.loading = false;
      }

    },
    getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        if (cookie.startsWith(name + '=')) {
          return cookie.substring(name.length + 1);
        }
      }
      return null;
    },
    setCookie(name, value, days) {
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + days);
      const cookieValue = `${name}=${value}; expires=${expirationDate.toUTCString()}; path=/`;
      document.cookie = cookieValue;
      this.isCookieSet = true;
      //fake click on generate prompt button
      this.generatePrompt();
      this.promptIsReady = true;
    },
    copyPrompt() {
      const promptTextArea = this.$refs.promptTextArea;
      promptTextArea.select();
      document.execCommand('copy');
      promptTextArea.setSelectionRange(0, 0); // Deselect the text

      // Show the "Prompt Copied" message for a few seconds
      this.generatedPromptCopied = true;
      setTimeout(() => {
        this.generatedPromptCopied = false;
      }, 1000);
    },
    toggleDarkTheme() {
      this.$emit('dark-theme-toggled', this.isDarkTheme);
    },
    generatePrompt() {

      if (!this.generalTopic) {
        this.generalTopicHasError = true;
        this.$refs.yourIdeaPromptError.style.display = 'block';
        this.$refs.yourIdeaPrompt.scrollIntoView();
        return; // Return early to prevent generating the prompt
      } else {
        if (this.isCookieSet) {
          this.isResultBlurred = false;
          this.generalTopicHasError = false;
          this.isCodeValid = true;
          this.subscribed = false;
          this.code = '';
          this.showModal = false;
          this.promptIsReady = true;
          this.$refs.yourIdeaPromptError.style.display = 'none';
          //check if jailbreak checkbox is checked
          if (this.isDarkTheme) {
            const promptParts = [];
            if (this.generalTopic) {
              promptParts.push(`Thema: ${this.generalTopic}`);
            }

            if (this.translations.dropdown.role[this.role]) {
              promptParts.push(`Rolle: ${this.translations.dropdown.role[this.role]}`);
            }

            if (this.translations.dropdown.resultType[this.resultType]) {
              promptParts.push(`Ergebnistyp: ${this.translations.dropdown.resultType[this.resultType]}`);
            }

            if (this.translations.dropdown.textLength[this.textLength]) {
              promptParts.push(`Textlänge: ${this.translations.dropdown.textLength[this.textLength]}`);
            }

            if (this.translations.dropdown.style[this.style]) {
              promptParts.push(`Stil: ${this.translations.dropdown.style[this.style]}`);
            }

            if (this.translations.dropdown.formality[this.formality]) {
              promptParts.push(`Formalität: ${this.translations.dropdown.formality[this.formality]}`);
            }

            if (this.translations.dropdown.creativity[this.creativity]) {
              promptParts.push(`Kreativität: ${this.translations.dropdown.creativity[this.creativity]}`);
            }

            if (this.translations.dropdown.challenge[this.challenge]) {
              promptParts.push(`Herausforderung: ${this.translations.dropdown.challenge[this.challenge]}`);
            }

            if (this.translations.dropdown.language[this.language]) {
              promptParts.push(`Sprache: ${this.translations.dropdown.language[this.language]}`);
            }

            if (this.translations.dropdown.textLanguage[this.textLanguage]) {
              promptParts.push(`Textsprache: ${this.translations.dropdown.textLanguage[this.textLanguage]}`);
            }

            if (this.translations.dropdown.textType[this.textType]) {
              promptParts.push(`Textart: ${this.translations.dropdown.textType[this.textType]}`);
            }
            // Include temperature in the prompt if it's not zero
            if (this.temperatureSlider > 0) {
              promptParts.push(`Temperatur: ${this.temperatureSlider}`);
            }

            var jailbreakP = this.translations.labels.jailbreakPrompt;
            //replace replaceMe with promptParts
            jailbreakP = jailbreakP.replace('replaceMe', promptParts.join('\n'));

            // Join all the prompt parts with line breaks
            this.generatedPromptTextArea = jailbreakP ;

          } else {
            const promptParts = [];
            if (this.generalTopic) {
              promptParts.push(`Thema: ${this.generalTopic}`);
            }

            if (this.translations.dropdown.role[this.role]) {
              promptParts.push(`Rolle: ${this.translations.dropdown.role[this.role]}`);
            }

            if (this.translations.dropdown.resultType[this.resultType]) {
              promptParts.push(`Ergebnistyp: ${this.translations.dropdown.resultType[this.resultType]}`);
            }

            if (this.translations.dropdown.textLength[this.textLength]) {
              promptParts.push(`Textlänge: ${this.translations.dropdown.textLength[this.textLength]}`);
            }

            if (this.translations.dropdown.style[this.style]) {
              promptParts.push(`Stil: ${this.translations.dropdown.style[this.style]}`);
            }

            if (this.translations.dropdown.formality[this.formality]) {
              promptParts.push(`Formalität: ${this.translations.dropdown.formality[this.formality]}`);
            }

            if (this.translations.dropdown.creativity[this.creativity]) {
              promptParts.push(`Kreativität: ${this.translations.dropdown.creativity[this.creativity]}`);
            }

            if (this.translations.dropdown.challenge[this.challenge]) {
              promptParts.push(`Herausforderung: ${this.translations.dropdown.challenge[this.challenge]}`);
            }

            if (this.translations.dropdown.language[this.language]) {
              promptParts.push(`Sprache: ${this.translations.dropdown.language[this.language]}`);
            }

            if (this.translations.dropdown.textLanguage[this.textLanguage]) {
              promptParts.push(`Textsprache: ${this.translations.dropdown.textLanguage[this.textLanguage]}`);
            }

            if (this.translations.dropdown.textType[this.textType]) {
              promptParts.push(`Textart: ${this.translations.dropdown.textType[this.textType]}`);
            }
            // Include temperature in the prompt if it's not zero
            if (this.temperatureSlider > 0) {
              promptParts.push(`Temperatur: ${this.temperatureSlider}`);
            }


            // Join all the prompt parts with line breaks
            this.generatedPromptTextArea = promptParts.join('\n');

          }
        } else {
          this.showModal = true;
          // Generate Lorem Ipsum prompt
          this.clearPrompt = 'At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa qui officia deserunt mollitia animi, id est laborum et dolorum fuga. Et harum quidem rerum facilis est et expedita distinctio. Nam libero tempore, cum soluta nobis est eligendi optio cumque nihil impedit quo minus id quod maxime placeat facere possimus, omnis voluptas assumenda est, omnis dolor repellendus. Temporibus autem quibusdam et aut officiis debitis aut rerum necessitatibus saepe eveniet ut et voluptates repudiandae sint et molestiae non recusandae. Itaque earum rerum';
          this.generatedPromptTextArea = this.clearPrompt;

          //add css blur to promptResult
          this.isResultBlurred = true;

          // Show the modal
          this.showModal = true;
          this.generalTopicHasError = false;

          this.$refs.yourIdeaPromptError.style.display = 'none';
          this.$refs.promptTextArea.scrollIntoView();
        }
      }

      setTimeout(() => {
        const promptTextArea = this.$refs.promptTextArea;
        promptTextArea.select();
        document.execCommand('copy');
        //promptTextArea.setSelectionRange(0, 0); // Deselect the text
      }, 100);
    }
  }
}
</script>

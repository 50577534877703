import { createApp } from 'vue';
import App from './App.vue';
import { createI18n } from 'vue-i18n';
import en from './locales/en.json';
import de from './locales/de.json';
//import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './css/style.css';

const messages = {
  en: en,
  de: de
};

const i18n = createI18n({
  locale: 'en', // set default locale
  messages
});

const app = createApp(App);
app.use(i18n);
app.mount('#app');

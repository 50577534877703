<template>
  <div>
    <language-switcher @language-changed="changeLanguage"></language-switcher>

    <DropdownGenerator :translations="translations" @dark-theme-toggled="toggleDarkTheme" />

  </div>
</template>

<script>
import DropdownGenerator from './components/DropdownGenerator.vue';
import LanguageSwitcher from './components/LanguageSwitcher.vue';
import enTranslations from './locales/en.json';
import deTranslations from './locales/de.json';


export default {
  components: {
    LanguageSwitcher,
    DropdownGenerator
  },
  data() {
    return {
      currentLanguage: 'EN',  // default language
      translations: this.loadTranslations('EN'),  // load default translations
      // ... other data properties ...
    }
  },
  methods: {
    loadTranslations(lang) {
      switch(lang) {
        case 'EN':
          return enTranslations; // Return the English translations
        case 'DE':
          return deTranslations; // Return the German translations
        default:
          console.error(`Unsupported language: ${lang}`);
          return {};
      }
    },
    changeLanguage(lang) {
      this.currentLanguage = lang;  // Assuming you have a data property for currentLanguage
      // Load the new translations for the selected language
      this.translations = this.loadTranslations(lang);
    },
    toggleDarkTheme(isDark) {
      if (isDark) {
        document.body.classList.add('dark-theme');
      } else {
        document.body.classList.remove('dark-theme');
      }
    }
  }
}
</script>

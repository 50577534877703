<!-- src/components/LanguageSwitcher.vue -->
<template>
  <div class="container mt-4">

    <div class="row">
      <div class="col-3">
        <div class="language-switcher">
          <select v-model="selectedLanguage" @change="setLanguage" class="languageSwitcher form-select">
            <option value="EN">English</option>
            <option value="DE">Deutsch</option>
            <!-- Add more languages as options if needed -->
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedLanguage: 'EN' // default language
    };
  },
  methods: {
    setLanguage() {
      this.$emit('language-changed', this.selectedLanguage);
    }
  }
}
</script>

<style scoped>
.language-switcher {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
